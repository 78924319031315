import React, { useEffect } from "react"

import { graphql, PageProps } from "gatsby"
import Layout from "../components/Layouts/mainPage"
import { PageData } from "../interfaces/pageProps"

import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/ZoonBanner"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { OtherCards } from "../components/OtherCards"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"
import { QUESTIONS_FOR_INDEX_ZOON } from "../components/Questions/helpers"
import { getPageData } from "../helpers/getPageData"
import { partnersZoon } from "../components/PartnersNew/helpers"

const titleForm = (
  <>
    Оформите карту сейчас <br /> – получите подарок!
  </>
)

const countDownTime = 1800000
const bannerDescription = `
— 24 месяца без %
<br />
— 17% на остаток по карте
<br />  
— Кэшбэк на покупки до 10%
`
const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)
  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex} phones={phones}>
      <NewHeader showHint={false} hasCTA additionalEventInDataLayer countDownTime={countDownTime} />
      <Banner orderNum="1" description={bannerDescription} />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <FormPKW
        dataLayerName="longPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
        navigateVerifySMS={false}
      />
      <HowInstallmentWorks
        headTitle="Как получить 1000&nbsp;баллов&nbsp;кэшбэка?"
        variant="zoon"
        orderNum="4"
      />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <OtherCards orderNum="6" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
        additionalPartners={partnersZoon}
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="8"
        hasSubtitle
        variant="pkwItemsNewHint"
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
      <Reviews additionalEventInDataLayer orderNum="10" />
      <Questions questionList={QUESTIONS_FOR_INDEX_ZOON} additionalEventInDataLayer orderNum="11" />
      <NewFooter ligal={ligal} orderNum="12" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/zoon/") {
        url
        phones
        ligal {
          text
        }
        notIndex
        shortTitle
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
